import React, { Component } from 'react';
import GenericPage from '../components/genericpage';
import data from '../data/average.json'


const AverageInvestor = () => {
  return (
    <GenericPage PageData = {data} />
  )
}

export default AverageInvestor